@import "../styles/colors";

#view-onboarding {
    background: #000;

    video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: -100;
        right: 0;
        bottom: 0;
        overflow: hidden;
    }
}

@at-root .landscape #view-onboarding video {
    object-fit: contain;
}