@font-face {
    font-family: 'hk_grotesk';
    src: url('../fonts/hkgrotesk-medium.eot');
    src: url('../fonts/hkgrotesk-medium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/hkgrotesk-medium.woff2') format('woff2'),
         url('../fonts/hkgrotesk-medium.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'hk_grotesk';
    src: url('../fonts/hkgrotesk-semibold.eot');
    src: url('../fonts/hkgrotesk-semibold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/hkgrotesk-semibold.woff2') format('woff2'),
         url('../fonts/hkgrotesk-semibold.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'hk_grotesk';
    src: url('../fonts/hkgrotesk-bold.eot');
    src: url('../fonts/hkgrotesk-bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/hkgrotesk-bold.woff2') format('woff2'),
         url('../fonts/hkgrotesk-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
