@import "../../../styles/colors";

#bankID-view {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  .loading {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.4);
  }
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 50vh;
    background: #fff;
    border-radius: 15px;
    padding: 5vh 1vh;
    .container {
      display: flex;
      align-items: center;
      flex-direction: column;
      button {
        max-width: 100%;
      }
    }
  }
  .error {
    color: $blue-darker;
    font-size: 3vw;
    margin-bottom: 20px;
    @at-root .landscape & {
      font-size: 3vh;
    }
  }
}
