@import "../../styles/colors";

#view-login {
  background: white;

  .header {
    height: 25% !important;
    min-height: 25% !important;
  }

  .main {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    height: 45%;
  }

  #login-input {
    border-radius: 10vw;
    background: white;
    color: $blue-darker;
    width: 60vw;
    height: 15vw;
    margin: 0 auto;
    padding: 2vw 0 1vw 2.5vw;
    font-size: 10vw;
    font-weight: bold;
    letter-spacing: 3.2vw;
    text-align: center;
    white-space: pre;

    @at-root .landscape & {
      width: 60vh;
      height: 10vh;
      padding: 1vh 0 3vh 2.5vh;
      font-size: 10vh;
      letter-spacing: 3.2vh;
    }
  }

  .numpad {
    position: relative;

    .row:last-of-type {
      padding-left: 8.5vh;
      position: relative;
      top: -1.5vh;
    }

    button {
      display: inline-block !important;
      width: 7.5vh;
      height: 7.5vh;
      margin: 0.7vh;
      font-size: 5vh;
      padding: 0;
      box-shadow: 0px 1vh 2vh rgba($blue-darker, 0.3);
      min-width: auto;

      &.backspace {
        box-shadow: none !important;
        border-radius: 0px;
        background: url(../../images/backspace.svg) no-repeat center center;
        background-size: cover;
        position: relative;
        top: 2.5vh;
      }
    }
    margin-bottom: 5%;

    @at-root .landscape & {
      margin-bottom: 1%;
    }
  }

  .error {
    color: $blue-darker;
    font-size: 3vw;

    @at-root .landscape & {
      font-size: 3vh;
    }
  }
}
