@import '../../styles/colors';
@import '../../styles/buttons';

#view-exercise_evaluation {

    .exercise-survey {
        width: 105%;
        margin-left: -2%;
        .exercise-survey-choice {
            @extend .survey-choice;
        }
    }

}