@import "colors";
@import "fonts";

* {
  user-select: none;
}

html, body {
  width: 100vw;
  height: 100vh;
}

body {
  background: transparent;
  font-family: 'hk_grotesk';
  color: #fff;
  overflow: hidden;
  &.mirror {
    background: #000;
  }  
}

@keyframes slideUp {
  from { top: 100%; }
  to { top: 0%; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.screen {
  //display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  &.slide-up {
    background: none !important;
    .content {
      position: absolute;
      background: white;
      width: 100%;
      height: calc(100% - 5vh);
      position: absolute;      
      animation: slideUp 1s forwards;
      margin-top: 5vh;
      padding-top: 3vh;
      border-radius: 8vw 8vw 0 0;
      z-index: 101;
    }
    .cover {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 100;
      background: rgba(0, 0, 0, .6) !important;
      animation: fadeIn 1s;   
    }
  }
}

.blobs {
  background: $yellow-light url(../images/blobs.svg) no-repeat center center !important;
  background-size: cover !important;
  color: $blue-darker;

  .wrapper {
    padding-top: 18vh !important;
  }
}
.blobs-light {
  background: $yellow-light url(../images/blobs-light.svg) no-repeat center center !important;
  background-size: cover !important;
  color: $blue-darker;
}

section {
  @extend .screen;
  z-index: 1;
  text-align: center;

  &.black {
    background: #000;
    * {
      color: #fff !important;
    }
  }
  &.white {
    background: #fff;
    *:not(.blue-dark) {
      color: $blue-darker;
    }
  }  

  &.white-transparent {
    background: rgba(255, 255, 255, .5);
    * {
      color: $blue-darker !important;
    }    
  }  

  .wrapper {
    padding: 20vw 5vw;
    &.middle {
      padding: 0 5vw;
      max-width: 100vh;
      height: 80%;
      display: flex;
      align-items: center;
      align-content: center;
    }
    &.small {
        padding: 10vw 5vw;
    }
  }

  .w-80 {
    width: 100%;
  }
  .w-100 {
    width: 100%;
  }
  .d-block {
    display: block;
  }
  .d-inline {
    display: inline;
  }
  .d-none {
    display: none;
  }

  h1 {
    margin: 3vh 0 5vh !important;
    font-size: 5vh;
    font-weight: 700;
    line-height: 6.5vh;

    &.superbig {
      font-size: 13vh;
    }
  }
  h2 {
    font-size: 4vh;
    font-weight: 700;
    margin: 0 0 3vh !important;
  }
  h3 {
    font-size: 3vh;
    font-weight: 700;
    margin: 2vh 0 3vh !important;
  }  
  h4 {
    font-size: 2vh;
  }  
  h5 {
    font-size: 1.5vh;
  }  
}

p {
  font-size: 2vh;
  margin: 0 0 2vh;
  padding: 0;
}

h1, h2, h3, p {
  width: 100%;
  &.yellow-light {
    color: $yellow-light;
  }
  &.yellow-dark {
    color: $yellow-dark;
  }  
}

.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.top {
  position: fixed;
  top: constant(safe-area-inset-top);
  top: env(safe-area-inset-top);
  margin-top: 2vh !important;
}
.left {
  left: 4vw;  
}
.right {
  right: 4vw;  
}
.bottom, .bottom-1-up {
  position: fixed;
  bottom: 4vh;
  @at-root .landscape & {
    left: 5vw !important;
  }
  &.bottom-1-up {
    bottom: 15vh;
    @at-root .landscape & {
      bottom: 16vh;
    }
  }
  left: 4vw;
  width: calc(100% - 8vw);
  &.left {
    width: calc(50% - 6vw);
  }
  &.right {
    left: auto;
    right: 4vw;
    width: calc(50% - 6vw);
  }  
}
.full-width {
  width: calc(100% - 8vw);  
}
.auto-width {
  width: auto !important;
}
.center {
  position: relative;
  margin: 0 auto;
}
.middle {
  display: flex;
  flex-wrap: wrap;
  margin: auto;

  * {
    margin: auto;
    text-align: center;
  }
}

.header {
  background: $yellow-light;
  padding: 8vh 10vw;
  min-height: 45%;
  box-sizing: border-box;
  margin-bottom: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card {
  margin: 8vw 12vw;
  padding: 3vw;
  border-radius: 4vw;
  font-size: 2.5vh;

  @at-root & {
    margin: 0 12vw;
  }

  &.blue-light {
    background: $blue-light;
  }
  &.yellow-dark {
    background: $yellow-dark;
  }
  &.white {
      background: white;
  }
  &.shadow {
    box-shadow: 0px .5vh 1.2vh rgba($blue-darker, .3);
  }
}

.thumb {
  background: $gray-lighter;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
      width: 90%;
      height: 90%;
  }
}