@import "../styles/colors";

@keyframes fadeIn {
    from { opacity: 0 }
    to { opacity: 1 }
}

#view-off {
    animation: fadeIn 2s;
}

#view-dashboard-installation {

    .header {
        background: #fff !important;
        height: 20vh !important;
        min-height: 20vh !important;
        margin-bottom: 0 !important;

        @at-root .landscape & {
            height: 40vh !important;

            h2 {
                font-size: 4vw;
            }

            p {
                font-size: 2vw;
            }
        }

        .fullscreen {
            position: absolute;
            right: 15px;
            top: 15px;
            width: 30px;
            height: 30px;
            background: top right no-repeat;
            background-size: contain;

            &.on {
                background-image: url(../images/fullscreen-on.svg);
            }
            &.off {
                background-image: url(../images/fullscreen-off.svg);
            }
        }
    }

    .programs-wrapper {

        height: 80vh;
        overflow-y: auto;

        @at-root .landscape & {
            height: 60vh;
            overflow-y: hidden;
            overflow-x: auto;
        }

        .programs {
            padding-bottom: 20vw;

            @at-root .landscape & {
                display: flex;
            }

            &:after {
                content: '';
                position: fixed;
                background: linear-gradient(0deg, rgba(255,255,2556,1) 50%, rgba(255,255,255,0) 100%);
                bottom: 0;
                left: 0;
                width: 100%;
                height: 20%;
                pointer-events: none;
            }

            .card {
                display: flex;
                margin: 4vw;

                @at-root .landscape & {
                    margin: 4vw 0 0 4vw;

                    &:last-child {
                        margin-right: 4vw !important;
                    }
                }

                .image {
                    flex: 2;
                    justify-content: center;
                    align-items: center;

                    .thumb {
                        width: 25vw;
                        height: 25vw;

                        @at-root .landscape & {
                            width: 8vw;
                            height: 8vw;
                        }
                    }

                    .circle {
                        width: 25vw;
                        height: 25vw;
                        border-radius: 50%;
                        padding: .5vw;
                        background: $gray-lighter;

                        @at-root .landscape & {
                            width: 8vw;
                            height: 8vw;
                        }
                    }
                }

                .text {
                    flex: 2;
                    text-align: left;
                    padding-left: 3vw;

                    @at-root .landscape & {
                        min-width: 20vw;
                
                        h4 {
                            font-size: 1.8vw;
                            margin: 0.5vw 1vw 0 0;
                        }
                        h5 {
                            font-size: 1.5vw;
                            margin: 1.5vw 0 0 0;
                        }
                    }
                }

                .arrow {
                    display: flex;
                    flex: 1;
                    align-items: center;
                    justify-content: center;

                    @at-root .landscape & {
                        svg {
                            transform: scale(1.2);
                        }
                    }
                }
            }

            /* Workaround due to missing margin-right with overflow */
            @at-root .landscape & {
                .space {
                    display: flex;
                    margin-left: 4vw;
                }
            }
        }

    }

    .text-wrapper {
        margin-top: 20vh;
    }

}