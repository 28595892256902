$gray-light:    #aaaaaa;
$gray-lighter:  #e1e1e1;
$blue-dark:     #231B8D;
$blue-darker:   #130E5D;
$blue-light:    #A8A1FF;
$blue-lighter:  #BCB7FF;
$yellow-dark:   #FFAE72;
$yellow-light:  #FFD5B6;
$orange:        #F9842D;
$green:         #04B700;
$green-dark:    #006817;
$red:           #DB3B38;

.color-red {
    color: $red !important;
}

.color-white {
    color: #fff !important;
}