@import "_colors";

.progress-bar {
    .main-caption {
        font-size: 1.6vh;
        text-align: center;
        height: 3vh;
        position: absolute;
        width: 100%;
    }
    .progress {
        width: 100%;
        display: flex;
        margin: 0 -1vw;

        &:last-of-type {
            margin: 0;
        }

        .item {
            flex-grow: 1;
            margin: 0 1vw;

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }

            .bar {
                background: gray;
                border-radius: 2vw;    
                width: 100%;            
                height: 2vw;
                margin-top: 3vh;

                @at-root .landscape & {
                    height: 1.5vw;
                    margin-top: 4vh;
                }

                &.white {
                    background: #fff;
                }
            }
            &.current {
                .caption {
                    font-size: 1.6vh;
                    text-align: center;
                    height: 3vh;

                    @at-root .landscape & {
                        font-size: 2.3vh;
                        height: 4vh;
                    }
                }                            
                .bar {
                    margin: 0;
                    background: #fff;     
                    .time {
                        background: $orange;
                        width: 0%;
                        height: 2vw;
                        border-radius: 2vw;    

                        @at-root .landscape & {
                            height: 1.5vw;
                        }
                    }        
                }
            }

            &.blue-dark {
                .caption {
                    color: $blue-dark !important;
                }
                .bar, .time {
                    background: $blue-dark !important;
                }
            }
        }        
    }

    &.inverted {
        .item {
            .bar {
                background: #fff;
                .time {
                    background: $orange;
                }
            }
        }
    }

    &.small {
        width: 25%;
        margin: 0 auto;
        .item {
            .bar {
                height: 1vw !important;
                .time {
                    height: 1vw !important;
                }
            }
        }
    }
  }