
#view-voice {
    .content {
        h2 {
            margin-bottom: 8vw !important;

            @at-root .landscape & {
                margin-bottom: 8vh !important;
            }
        }
    }

    .recording-meter {
        width: 50vw;
        height: 50vw;
        top: 10vh;
        left: 25vw;

        @at-root .landscape & {
            width: 30vh;
            height: 30vh;
            left: 50%;
            margin-left: -15vh;
        }

        .layer {
            background: no-repeat center center;
            position: absolute;
            width: 100%;
            height: 100%;
        }
        .layer-1 {
            background-image: url(../../images/recording-meter-1.svg);
            background-size: 30%;
        }
        .layer-2 {
            background-image: url(../../images/recording-meter-2.svg);
            background-size: 60%;
        }
        .layer-3 {
            background-image: url(../../images/recording-meter-3.svg);
            background-size: 80%;
        }
        .layer-4 {
            background-image: url(../../images/recording-meter-4.svg);
            background-size: 100%;
        }
    }
}