@import "../styles/colors";

@keyframes fadeIn {
    from { opacity: 0 }
    to { opacity: 1 }
}

#view-off {
    animation: fadeIn 2s;
    z-index: 2000;
}

.aborted-exercise {
    .thumb {
        width: 25vw;
        height: 25vw;
        margin-bottom: 2vh;

        @at-root .landscape & {
            width: 15vw;
            height: 15vw;
        }
    }
}
