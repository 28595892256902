@import "../../styles/colors";

#view-exercise_intro {

    .header {
        margin: 0;
        padding: 3vh 3vw;
        min-height: 50%;

        @at-root .landscape & {
            height: 40%;
            min-height: auto;
        }
        
        h1 {
            margin-top: 0 !important;
            margin-bottom: 3vh !important;
        }

        h3 {
            margin-bottom: 1vh !important;
        }

        h4 {
            @at-root .landscape & {
                font-size: 1.7vw;
            }
        }

        p {
            font-size: 3vh;
            margin: 0;

            @at-root .landscape & {
                font-size: 2vw;
            }

            @at-root .landscape & > br {
                display: none;
            }

        }

        .card {
            margin: 1vh 5vw 3vh 5vw !important;
            padding: 0 3vw 0 !important;
        }
    }

    .exercises-wrapper {
        width: 115%;
        max-width: 100vh;
        height: 50%;
        overflow: scroll;
        position: absolute;

        @at-root .landscape & {
            width: 100%;
            height: auto;
            min-height: 60%;
            max-width: none;
        }
    }

    .exercises {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 30vw;

        @at-root .landscape & {
            flex-wrap: nowrap;
            min-height: 60vh;
        }

        .exercise {
            flex-direction: column;
            margin: 0;
            padding: 1vh 2.2vw 2vh;
            width: 25%;
            border-right: solid 1px $blue-light;
            border-bottom: solid 1px $blue-light;
            justify-content: center;
            align-items: center;

            @at-root .landscape & {
                justify-content: flex-start;
                width: calc(100vw / 3);
                height: 60vh;
                padding: 1vh 2vw 2vh;
                border-bottom: none;
            }

            @at-root body:not(.landscape) &:nth-child(3n) {
                border-right: none;
            }

            h3 {
                margin: 0 !important;
                font-size: 3.5vh;
                @at-root .landscape & {
                    font-size: 4vh;
                    width: 100%;
                }
            }
            h4 {
                margin-top: 0;
                height: 5vh;
                font-size: 1.7vh;
                @at-root .landscape & {
                    font-size: 1.5vw;
                    height: 3.6vw;
                }
            }
            .thumb {
                width: 25vw;
                height: 25vw;
                margin: 0 auto;
                @at-root .landscape & {
                    width: 15vw;
                    height: 15vw;
                }
            }
        }
        &:after {
            content: '';
            position: fixed;
            background: linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
            bottom: 0;
            left: 0;
            width: 100%;
            height: 20%;
            pointer-events: none;

            @at-root .landscape & {
                background: linear-gradient(90deg, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%);
                left: auto;
                right: 0;
                top: 50%;
                width: 20%;
                height: 100%;
            }
        }
    }

}