#meeting-view {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: #000;
}

#meeting-admin-view {
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .video-view {
        animation: fadeIn 2s;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    }

    video {
        object-fit: cover;
        object-position: center;
        z-index: -1;
    }
}

#meeting-self-view {
    position: fixed;
    bottom: 15vh;
    right: 2vh;
    width: 17vh;
    
    video {

    }
}