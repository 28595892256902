@keyframes slideIn {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

#booking-notification-view {
    position: absolute;
    left: 0;
    top: 0;
    width: 80vw;
    height: auto !important;
    background: #fff;
    padding: calc(env(safe-area-inset-top) + 2.5vw) 5vw 2.5vw 5vw;
    z-index: 1000;
    margin: 0 5vw;
    border-radius: 0 0 10vw 10vw;
    color: #000;
    animation: slideIn 0.5s ease-out forwards;
}