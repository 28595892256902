@import '../../styles/main';


#view-exercise {

    background: #000;
    //opacity: 0.5;

    .top-gradient {
        width: 100vw;
        height: 30vh;
        background: linear-gradient(180deg, rgba(0, 0, 0, .7) 25%, rgba(0, 0, 0, 0) 100%);
        position: fixed;
        left: 0;
        top: 0;
    }

    #mirror, #mirror-canvas, #mirror-blur {
        position: absolute;
        top: 50%; 
        left: 50%;
        min-width: 100%; 
        min-height: 100%;
        width: auto; 
        height: auto;
        transform: translateX(-50%) translateY(-50%);
        z-index: -1000; 
        overflow: hidden;
        opacity: 1;
        transition: opacity 1s;
        &.hidden {
            opacity: 0;
            transition: opacity 2s;
        }
    }

    #mirror, #mirror-blur {
        transform: translateX(-50%) translateY(-50%) scaleX(-1);
    }

    #mirror-blur {
        z-index: -999;
    }

    .video-transform {
        position: absolute;
        width: 40vw;
        height: 40vw;
        top: 25%;
        right: 10%;
    }
    .video-element {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        transition: opacity 1s;
        display: block;
        
        &.hidden {
            opacity: 0;
            transition: opacity 1s;
            transition-delay: .3s;
        }
        &::cue {
            font-family: 'hk_grotesk';
            font-size: 4vw;

            @at-root .landscape & {
                font-size: 4vh;
                //width: 69%;
            }
        }
    }

    .video-subtitles {
        padding: 0 5vw;
        position: absolute;
        left: 0;
        bottom: 15vh;
        text-align: left;
        max-width: 85vw;
        transition: opacity .5s;

        &.hidden {
            opacity: 0;
            transition: opacity .5s;
        }

        p {
            display: block;
            border-radius: 2vw;
            padding: 1vw 2vw;
            font-family: 'hk_grotesk';
            font-size: 4vw;
            color: #fff;
            line-height: 6vw;
            background-color: rgba(0, 0, 0, .7);
    
            @at-root .landscape & {
                margin: 0 auto;
                font-size: 4vh;
                //width: 69%;
                line-height: 6vh;
                border-radius: 2vh;
            }
        }

    }
    
    #mirror, #mirror-canvas {
        &.rotate-90 {
            transform: rotate(90deg) scale(1.4);
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            min-width: 100%;
            min-height: 100%;
        }
        &.rotate--90 {
            transform: rotate(-90deg) scale(1.6) scaleY(-1);
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            min-width: 100%;
            min-height: 100%;
        }
    }

    #mirror-mask {
        @extend .screen;
        z-index: -999;
        background: radial-gradient(circle at left, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 1) 100%);
      }

    .reps {

        @extend .left;        
        position: absolute;
        top: 8vh;

        @at-root .landscape & {
            top: 12vh;
        }


        .caption {
            display: block;
            margin-bottom: 2vw;
            font-size: 3.2vw;

            @at-root .landscape & {
                font-size: 1.7vw;
                margin-bottom: 1.5vw;
            }
        }

        .current-rep {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 13vw;
            height: 13vw;
            border-radius: 50%;
            background: #fff;
            color: #000;
            font-size: 9vw;
            text-align: center;

            @at-root .android & {
                //font-size: 8.8vw;
                //line-height: 11.7vw;
                //background: red;
            }

            @at-root .landscape & {
                width: 8vw;
                height: 8vw;
                font-size: 6vw;
            }
        }
        .total-reps {
            position: relative;
            top: -11vw;
            left: 15.5vw;
            font-size: 6vw;
            white-space: nowrap;
            text-align: left;

            @at-root .landscape & {
                top: -6vw;
                left: 9.5vw;
                font-size: 3vw;
            }
        }
    }

}

@keyframes fadeOut {
    from { opacity: 1 };
    to { opacity: 0; display: none !important; }
}

#view-exercise-countdown, #view-loading-pose-detection {
    background-color: rgba(0, 0, 0, .7);
    pointer-events: none;
    &.out {
        animation: fadeOut 2s forwards;
    }

    h1 {
        margin-bottom: 10vw;
    }
}