@import "../../styles/colors";

.content {
    button {
        margin-bottom: 2vh;
    }
}

/* Overwrite section style */
section.white .color-red {
    @extend .color-red;
}

.scroll-cards {
    width: 200vw;
    display: flex;
    transition: margin 1s;

    &.open {
        margin-left: -100vw;
        transition: margin 1s;
    }

    .col-100 {
        width: 100vw;
    }
}

.reminders-wrapper {
    overflow: hidden;
    width: 70vw;
    height: 43vh;
    padding: 2vh 0;

    .scroll-cards h3 {
        margin-bottom: 0 !important;
    }
}

.scroll-reminders {
    display: flex;
    height: 100%;
    transition: margin 1s;
    width: 140vw;

    &.open {
        margin-left: -70vw;
        transition: margin 1s;
    }

    .col-100 {
        height: 100%;
        
        &:first-child {
            padding: 2vh 0 2vh;
            overflow-y: auto !important;

            button:last-child {
                margin-bottom: 11vh;
            }
        }
        width: 100vw;
    }
}

.audio-settings {

    @at-root .landscape & {
        display: flex;
        justify-content: space-between;
        padding-bottom: 4vh;
    }

    h4 {
        font-size: 2.2vh;
        @at-root .landscape & {
            font-size: 2vw;
        }
        margin-bottom: 0;
    }

    .audio-volume {
        display: flex;
        margin: 2vw 0 6vw 0;
        text-align: center;
        justify-content: center;
        align-items: center;

        @at-root .landscape & {
            width: auto;
            margin: 2vh 0 2vh 0;
        }

        button.circle {
            @at-root .landscape & {
                width: 50px;
                height: 50px;
            }
        }

        .value {
            width: 30%;
            max-width: 17vh;
            font-size: 6vw;

            @at-root .landscape & {
                width: 10vw;
                max-width: auto;
                font-size: 3vw;
            }
        }
    }

}

.workout-programs {
    display: flex;
    width: 68vw;
    left: 12vw;
    bottom: calc(16vh + 10px);
    z-index: 201;
    padding: 0 2vh;
    margin-bottom: 4vh;
    overflow: visible;
    overflow-x: scroll;
}

.workout-selection {
    /*
    width: 60vw !important;
    box-shadow: .8vw .8vw 2vw .25vw rgba(0,0,0,0.5);
    border-radius: 5vw;
    margin-right: 5vw;
    padding: 3vw 4vw;
    */

    .program-wrapper {
        display: flex;
        width: 60vw;
    }

    h3 {
        font-size: 2.5vh;
        margin-bottom: 2vh !important;
    }

    .workout-select {
        margin-right: 5vw;
    }

    .time-select {
        left: auto !important;
        right: -10vw !important;
        margin-left: -20vw; 
        top: -4vw !important;

        @at-root .landscape & {
            margin-left: -5vw;
            top: -3vw !important;
        }

        .mbsc-fr-w, .mbsc-fr-c {
            border: none !important;
            background: transparent !important;
        }
        .mbsc-sc-whl-o {
            background: transparent !important;
        }
        .mbsc-sc-whl-c, .mbsc-sc-whl-l {
            border: none !important;
            background: none !important;
        }
        .mbsc-sc-itm {
            font-size: 3vw !important;
            @at-root .landscape & {
                font-size: 1.5vw !important;
            }
        }
        .mbsc-sc-itm-sel {
            transition: all .5s !important;
            color: $orange !important;
            font-size: 4vw !important;
            @at-root .landscape & {
                font-size: 2.25vw !important;
            }
        }
    }
}

.select-music {
    height: 40vh;
    margin-bottom: 5vh;
    overflow: scroll;

    button:last-child {
        margin-bottom: 8vh;
    }
}

.select-music-fader {
    position: absolute;
    z-index: 1000;
    background: linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    bottom: 32vh;
    width: 70vw;
    height: 10vh;
    pointer-events: none;
}

.version {
    position: absolute;
    left: 4vw;
    bottom: 4vh;
    font-size: 2vh;
}