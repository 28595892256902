#view-incoming-call {
    .portrait-container {
        width: 50vw;
        height: 50vw;
        overflow: hidden;
        border: solid 1.5vw #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 2.5vh;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
      }

      h3 {
        margin: 0 0 1vh 0 !important;
      }
}